import AOS from "./common/aos.mjs";
import "./millar.com/modals.mjs";
import Carousel from "./millar.com/Carousel.mjs";
import Wiper from "./millar.com/Wiper.mjs";
import View from "./millar.com/View.mjs";
import Tab from "./millar.com/Ta11y.mjs";
import VideoPlayer from "./millar.com/VideoPlayer.mjs";
import MultiSelect from "./common/multi-select.mjs";
import "./millar.com/nav.mjs";

const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const header = $(".page-header");
const footer = $(".page-footer");

//#region Animations
AOS.init({
  duration: 750,
  once: true
});
//#endregion

//#region Sticky elements
new IntersectionObserver(
  ([entry]) => {
    entry.target.classList.toggle("stuck", entry.intersectionRatio < 1)
  },
  {
    threshold: [1]
  }
).observe(header);

// Back to top component.
// It is a container of the button, as the last element of <main>.
// It is offset by certain number of screens, and takes remaining height
// toward the footer.
// We use an IntersectionObserver with special rootBounds to detect
// when the component is in and out of viewport.
// When in view, attach a scroll handler, and remove it when out of view.
$$(".back-to-top").forEach(function (button) {
  const classList = button.classList;

  let scrollPos = 0;
  let scrollUp = false;
  // Simple scroll handler to detect scroll direction and toggle
  // the button's animation.
  function onScroll(e) {
    const bodyTop = document.body.getBoundingClientRect().top;

    // Scrolls up while scrolling down.
    if (bodyTop > scrollPos && !scrollUp) {
      classList.add("aos-animate");
      window.removeEventListener("scroll", onScroll);
    }

    // Saves the new state
    scrollPos = bodyTop;
    scrollUp = bodyTop > scrollPos;
  };

  new IntersectionObserver(
    ([entry]) => {
      // In the zone.
      if (entry.isIntersecting) {
        window.addEventListener("scroll", onScroll);
      } else {
        classList.remove("aos-animate");
        window.removeEventListener("scroll", onScroll);
      }

      // Resets flags.
      scrollPos = 0;
      scrollUp = false;
    },
    {
      // Use rootMargin to make the rootBounds as a single 0 height at the bottom of root.
      rootMargin: "-100% 0px 0% 0px",
    }
  ).observe(button);

});

//#endregion

//#region Carousels
$$(`.carousel`).forEach(el => {
  const itemList = el.querySelector(`[typeof="ItemList"]`);
  if (!itemList) {
    return;
  }

  const previousBtn = $(`.filters .previous`, el);
  const nextBtn = $(`.filters .next`, el);
  const liveregion = $("[aria-live]", el);

  itemList.addEventListener("change", (event) => {
    const { toIndex, numberOfItems } = event.detail;

    previousBtn && previousBtn.setAttribute("aria-disabled", toIndex === 0);
    nextBtn && nextBtn.setAttribute("aria-disabled", toIndex === (numberOfItems - 1));

    liveregion && (liveregion.innerHTML = `${ toIndex + 1 } of ${ numberOfItems }`);
  });

  const carousel = new Carousel(itemList, {
    itemSelector: `[property="itemListElement"]`,
  });

  if (previousBtn) {
    previousBtn.addEventListener("click", (event) => {
      carousel.back();
    });
  }

  if (nextBtn) {
    nextBtn.addEventListener("click", (event) => {
      carousel.next();
    });
  }

});
//#endregion

//#region Sketch to Render animation
const sketch = $(".sketch");
if (sketch) {
  new Wiper(sketch, $(".render"), $(".renderContainer"));
}
//#endregion

//#region Accordions
$$(`.accordion details, .tabs details`).forEach(details => {
  const summary = $("summary", details);
  if (!summary) {
    return;
  }

  const container = details.parentElement;
  const all = $$("details", container);

  summary.addEventListener("click", (event) => {
    all.forEach(sibling => {
      if (sibling !== details) {
        sibling.removeAttribute("open");
      }
    });
  });
});
//#endregion

//#region Tabs
$$(`[role="tablist"]`).forEach(tablist => {
  new Tab(tablist);
})
//#endregion

//#region Comparison slider
// The only thing this does is to inject a .resizer element in the "before"
// so that it can be used as the drag handle, and then adds a class as a flag.
$$(".comparison-slider").forEach(slider => {
  const before = $("li:nth-last-child(n+2):first-child", slider);
  const resizer = document.createElement("span");
  resizer.classList.add("resizer");
  before.appendChild(resizer);

  // When the slider becomes visible, we adjusts the resizer
  // so that it's in the middle.
  new IntersectionObserver(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        const width = entry.boundingClientRect.width / 2;
        resizer.style.width = `${width}px`;
        slider.classList.add("upgraded");
        observer.disconnect();
      }
    },
  ).observe(slider);
});
//#endregion

//#region Anchor links

// Make the first item active.
$$(".circular").forEach(circular => {
  $("a[href]", circular).classList.add("active");
});

const handleHash = function({newURL, oldURL}) {
  let oldHash, newHash = window.location.hash;
  if (oldURL) {
    oldHash = new URL(oldURL).hash;
  }

  if (!newHash) {
    return;
  }

  // Open target accordion.
  const summary = $(`details${ newHash }:not([open]) summary`);
  if (summary) {
    summary.click();
  }

  const links = $$(`.circular a[href="${ newHash }"]`);
  links.forEach(link => {
    const circular = link.closest(".circular");
    const siblingsAndSelf = [...$$("a[href]", circular)];
    const count = siblingsAndSelf.length;
    const index = siblingsAndSelf.indexOf(link);
    /** @type number */
    let previousIndex;

    circular.classList.add("transform");

    // Remove active class, but keep index of previous one.
    siblingsAndSelf.forEach((el, i) => {
      if (el.classList.contains("active")) {
        previousIndex = i;
        el.classList.remove("active");
      }
    });
    // Make this one active.
    link.classList.add("active");

    // Remove previous rotation class.
    if (previousIndex > 0) {
      circular.classList.remove(`-rotate-${ previousIndex }/${ count}`);
    }
    // Add new rotation class.
    if (index > 0) {
      circular.classList.add(`-rotate-${ index }/${ count }`);
    }
  });

  const thumbnail = $(`a[href="${ newHash }"].thumbnail`);
  if (!oldHash && thumbnail) {
    thumbnail.classList.add("active");
  }

  const target = document.getElementById(newHash.replace(/^#/, ""));
  if (!target) {
    return;
  }

  const dialog = $("dialog", target);
  if (dialog) {
    if (dialog.classList.contains("modal")) {
      dialog.showModal();
    } else {
      dialog.show();
    }
  }


}

window.addEventListener("load", handleHash);
window.addEventListener("hashchange", handleHash);
//#endregion

//#region View
$$(".view").forEach(el => {
  let loadMoreButton;

  el.addEventListener("paginate", (event) => {
    const { offset, items } = event.detail;

    if (!loadMoreButton) {
      loadMoreButton = document.createElement("button");
      loadMoreButton.classList.add("button");
      loadMoreButton.textContent = "Load More";

      loadMoreButton.addEventListener("click", () => {
        view.paginate();
      }, false);

      const container = document.createElement("div");
      container.classList.add("flex-1", "text-center");
      container.append(loadMoreButton);
      el.append(container);
    }

    loadMoreButton.style.display = offset >= items.length ? "none" : "";
  });

  const view = new View(el);
});
//#endregion

//#region Videos
$$("video").forEach(video => new VideoPlayer(video));
//#endregion

//#region Multi-selects
window.addEventListener("load", () => {
  document.querySelectorAll("select[multiple]").forEach((select) => {
    new MultiSelect(select);
  });
});
//#endregion

//#region Forms

// Add a button to toggle password with JS, since it won't
// work without anyway.
$$(`form input[type="password"]`).forEach(input => {
  const toggle = document.createElement("a");
  toggle.classList.add("password-toggle");
  toggle.textContent = "Show";

  toggle.addEventListener("click", (event) => {
    const on = input.type === "password";
    if (on) {
      input.type = "text";
      toggle.textContent = "Hide";
    } else {
      input.type = "password";
      toggle.textContent = "Show";
    }

    toggle.classList.toggle("active", on);
  });

  input.parentNode.insertBefore(toggle, input);
});

function onFormChange(event) {
  const form = this;
  const input = event.target;

  if (!input) {
    return;
  }

  if (input.name === "Country") {
    const stateInput = $(`select[name="State"]`, form);
    const available = input.value === "United States";
    stateInput.disabled = !available;

    if (available) {
      stateInput.parentNode.style.display = "";
    } else {
      stateInput.parentNode.style.display = "none";
    }
  }
}

$$("form").forEach(form => {
  form.addEventListener("change", onFormChange);

  // Checks initial country selection.
  onFormChange({
    target: $(`select[name="Country"]`, form),
  });
});

//#endregion
