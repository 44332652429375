function wrap(el, container) {
  el.parentNode.insertBefore(container, el);
  container.appendChild(el);
}

// Observer to pause video when not in view.
let observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(({ target, intersectionRatio }) => {
    const video = /** @type HTMLMediaElement */ (target);
    if (intersectionRatio !== 1  && !video.paused) {
      video.pause();
    }
  });
}, {
  threshold: 1
});

const defaultOptions = {

}

class VideoPlayer {
  constructor(video, options = {}) {
    options = {...defaultOptions, ...options};

    // Wraps the video with a container.
    const container = document.createElement("figure");
    container.classList.add("media");
    wrap(video, container);

    // Hides the controls
    video.controls = false;

    // Creates the controls
    const playing = video.autoplay || !video.paused;
    const controller = document.createElement("ul");
    controller.classList.add("menu");

    const playPauseControl = document.createElement("button");
    playPauseControl.type = "button";
    playPauseControl.title = playing? "Pause" : "Play";
    controller.appendChild(playPauseControl);
    wrap(playPauseControl, document.createElement("li"));

    playPauseControl.addEventListener("click", event => {
      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }
    });

    // Places the controller after the video.
    container.insertBefore(controller, video.nextSibling);

    // Events
    video.addEventListener("play", (event) => {
      playPauseControl.title = "Pause";
    });
    video.addEventListener("pause", (event) => {
      playPauseControl.title = "Play";
    });

    // Observer to pause the video when not in view
    observer.observe(video);
  }
}

export default VideoPlayer;
