const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
const pad = (number) => ("" + number).padStart(2, "0");

// normalize linefeeds for textareas
// https://html.spec.whatwg.org/multipage/form-elements.html#textarea-line-break-normalisation-transformation
const normalizeLinefeeds = (value) => value.replace(/\r\n/g, '\n').replace(/\n/g, '\r\n');

function each(arr, cb) {
  for (let i = 0; i < arr.length; i++) {
    cb(arr[i])
  }
}

class View {
  constructor(el) {
    this.el = el;
    /** @type HTMLFormElement */
    const form = this.form = $("form.filters", el);
    /** @type HTMLElement */
    const list = this.list = $(`[typeof="ItemList"], [itemtype*="ItemList"], .itemlist`, el);
    this.counter = $(`[property="numberOfItems"], [itemprop="numberOfItems"]`, el);

    if (list) {
      /** @type Element[] */
      this.items = Array.from($$(`[property="itemListElement"], :scope > [itemprop]`, list));
      this.pagination = {
        items: [],
        offset: 0,
        perPage: undefined,
      };
    }

    if (form) {
      form.addEventListener("change", event => {
        this.filter();
      });

      form.addEventListener("click", event => {
        const target = /** @type HTMLElement */(event.target);
        if (target.matches(".tag a")) {
          event.preventDefault();

          const tag = target.parentElement;
          const [name, value] = tag.title.split(": ");

          const control = $(`[title="${ name }"]`, form) || $(`[name="${ name }"]`, form);
          const option = $(`option[value="${ value }"]`, control);

          if (option) {
            option.selected = !option.selected;
          } else {
            control.value = "";
          }
          this.filter();
        }
      });

      form.addEventListener("reset", event => {
        // Filter on the next frame because "reset" event happens before
        // the form controls change.
        setTimeout(() => this.filter());
      });

      form.addEventListener("submit", event => {
        event.preventDefault();
      });

      this.filter(false);
    }
  }

  filter(setFocus = true) {
    const { form, list, items = [], pagination } = this;
    const filters = {};
    let perPage;

    // Store filters into an object because FormData does not handle
    // multiple select well. If a select has 3 selected options, the key
    // for it will appear three times, and `getAll` will return the same
    // values three times.
    // Using objects will keep one name one value pair.
    each(form.elements, (elm) => {
      const { name, type, value, disabled } = elm;

      if (!name || disabled || type === "submit" || type === "button" || type === "output") return;
      if (value === "" || typeof value === "undefined") return;

      // PerPage setting is not a filter. We use it to paginate the filtered result.
      if (name === "perPage") {
        perPage = parseInt(value);
        return;
      }

      let values = filters[name];
      if (typeof values === "undefined") {
        values = filters[name] = [];
      }

      if (type === "select-multiple" || type === "select-one") {
        each(elm.options, option => {
          !option.disabled && option.selected && values.push(option.value);
        });
      } else if (type === "checkbox" || type === "radio") {
        if (elm.checked) values.push(value);
      } else {
        values.push(type === "textarea"? normalizeLinefeeds(value) : value);
      }
    });

    const output = $("output", form);

    // Reset output.
    if (output) {
      output.value = "";
    }

    for (const name in filters) {
      const values = filters[name];

      if (output) {
        const control = form[name];
        const prefix = control.title || control.name;

        output.innerHTML +=
          values
          .map(value => this.tag(`${ prefix }: ${ value }`))
          .join(`<span class="a11y-sr-only">;</span>`);
      }
    }

    const filteredItems = pagination.items;
    filteredItems.length = 0;

    items.forEach((item, index) => {
      item.setAttribute("hidden", "hidden");

      // Default to matched to return all items when no filter is set.
      let matched = true;
      for (const name in filters) {
        const values = filters[name];
        // Early escape.
        if (!matched) break;

        const control = form[name];
        // @see https://developer.mozilla.org/en-US/docs/Web/API/Node/textContent
        // for differences between textContent and innerText.
        // Especially that reading innerText will trigger a reflow...
        const textContents = [];

        if (name === "keyword") {
          textContents.push(item.textContent);
        } else if (name === "numberOfItems") {
          matched = values.some(value => index < parseInt(value));
          break;
        } else {
          /** @type HTMLElement */
          const targets = $$(`[property="${ name }"], [itemprop="${ name }"]`, item);
          targets.forEach(target => {
             // For date time value, we use the `datetime` attribute instead.
             const datetime = target.getAttribute("datetime");
             if (datetime) {
               textContents.push(datetime);
             } else {
               textContents.push(target.textContent);
             }
          });
        }

        const textContent = textContents.join(", ");

        if (!textContent) {
          matched = false;
          break;
        }

        // As long as there is one filter matches then this item is matched.
        matched = values.some(value => new RegExp(`\\b${ value }\\b`).test(textContent));

        // If there is a `min` set, then we check differently.
        // The condition will work, since the value will be text, even for number 0.
        if (!matched && control.min) {
          // Handles case when it's a substition for today.
          const today = new Date();
          const min = control.min
            .replace("YYYY", today.getFullYear())
            .replace("MM", pad(today.getMonth() + 1))
            .replace("DD", pad(today.getDate()));

          matched = textContent >= min;
        }
      };

      if (matched) {
        item.removeAttribute("hidden");
        filteredItems.push(item);
      }
    });

    // Paginate from 0 again.
    pagination.offset = 0;
    this.paginate(perPage);

    this.counter && this.counter.setAttribute("content", filteredItems.length);
  }

  paginate(perPage = this.pagination.perPage) {
    if (!perPage) {
      return;
    }

    const { list, pagination } = this;
    const { items, offset } = pagination;
    const count = items.length;
    const index = offset + perPage;
    for (let i = 0; i < count; i++) {
      items[i].hidden = i >= index;
    }

    pagination.perPage = perPage;
    pagination.offset = index;

    list.dispatchEvent(new CustomEvent("paginate", {
      detail: pagination,
      bubbles: true,
    }));

    return pagination;
  }

  tag(value) {
    // We use `aria-label` on the remove button since we want to leave it empty
    // for the `output.textContent` not to include the text.
    return `<span class="tag" title="${ value }">${ value }<a href="#" aria-label="Remove filter for ${ value }"></a></span>`;
  }
}

export default View;
