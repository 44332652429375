document.querySelector(".nav--primary")?.addEventListener("click", (event) => {
  const target = event.target.closest("li");
  const parent = target.parentNode;

  if (!target.classList.contains("isParent") || document.querySelector(".mobile-menu").offsetParent === null) {
    return;
  } else if (target.classList.contains("open")) {
    event.preventDefault();
    target.classList.remove("open");
  } else {
    event.preventDefault();
    parent.querySelectorAll(".open").forEach(op => op.classList.remove("open"));
    target.classList.add("open");
  }
});
