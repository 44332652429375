
const defaultOptions = {
  startIndex: 0, // The initial starting slide index.
  itemSelector: "",
}

const observerOptions = {
  rootMargin: "0px",
  threshold: 0.25
}

class Carousel {

  /**
   * Creates an instance of Carousel.
   * @param {Element} el
   * @param {object} [options={}]
   * @memberof Carousel
   */
  constructor(el, options = {}) {
    options = {
      ...defaultOptions,
      ...options
    };

    const startIndex = parseInt(el.getAttribute("data-carousel-start") || options.startIndex);
    const autoplay = el.getAttribute("data-autoplay") || options.autoplay;
    const itemSelector = options.itemSelector;

    this.el = el;
    const items = this.items = new Array(
      ...(itemSelector? el.querySelectorAll(itemSelector) : el.children)
    );

    // To be notified of elements coming into view.
    const observer = new IntersectionObserver(this.update.bind(this), observerOptions);

    items.forEach(item => {
      observer.observe(item);

      const id = item.id;
      // Clicking on an anchor to an item will jump the page to the top of the item.
      // This is acceptable for browsers with JS disabled, but we can enhance it with JS
      // so that the user experience is not jarring.
      const anchors = document.querySelectorAll(`a[href="#${ id }"]`);
      anchors.forEach(anchor => {
        anchor.addEventListener("click", event => {
          // Disable jumping around and URL updates
          event.preventDefault();
          // Mark clicked anchor active.
          this.go(item);

          const previousItem = this._previousItem;
          const previousAnchors = document.querySelectorAll(`a[href="#${ previousItem.id }"]`);
          previousAnchors.forEach(a => a.classList.remove("active"));

          anchors.forEach(a => a.classList.add("active"));
        });
      });
    });

    const hash = window.location.hash;
    let activeItem = items[startIndex];
    if (hash) {
      const item = el.querySelector(hash);
      if (item) {
        activeItem = item;
        el.scrollTo({left: item.offsetLeft});
      }
    }

    this.activeItem = activeItem;
  }

  go(item) {
    const hash = `#${ item.id }`;
    const el = this.el;

    history.pushState({}, "", hash);

    // go back and then come forward again immediately, to force :target to update.
    history.back();
    const onpopstate = window.onpopstate;
    window.onpopstate = function() {
      history.forward();
      window.onpopstate = onpopstate;
      el.scrollTo({left: item.offsetLeft});
    };

    this.activeItem = item;
  }

  set activeItem(item) {
    this._previousItem = this._activeItem;
    this._activeItem = item;

    const items = this.items;
    this.el.dispatchEvent(new CustomEvent("change", {
      detail: {
        fromItem: this._previousItem,
        fromIndex: items.indexOf(this._previousItem),
        toItem: item,
        toIndex: items.indexOf(item),
        numberOfItems: items.length,
      }
    }));
  }

  back() {
    const items = this.items;
    const current = this._activeItem;
    const index = items.indexOf(current);

    if (index > 0) {
      this.go(items[index - 1]);
    }
  }

  next() {
    const items = this.items;
    const current = this._activeItem;
    const index = items.indexOf(current);

    if (index < items.length - 1) {
      this.go(items[index + 1]);
    }
  }

  update(entries) {
    // Set `aria-hidden` for entries in or out of view.
    entries.forEach(entry => {
      if (entry.intersectionRatio >= 0.25) {
        entry.target.setAttribute("aria-hidden", "false");
      } else {
        entry.target.setAttribute("aria-hidden", "true");
      }
    });
  }
}

export default Carousel;
