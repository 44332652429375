/*
  wiper.sketchHome: The original sketch element
  wiper.renderEl: The rendered element
  wiper.containerEl: The rendered element container (the orange box)
  wiper.fixedSketch: A clone of the sketch element that we control
  wiper.sketchDiv: A container for fixedSketch that we use for cropping
*/

class Wiper {

  constructor(sketch, render, renderContainer) {

    // Collect our elements
    this.sketchHome = sketch;
    this.renderEl = render;
    this.containerEl = renderContainer;

    new IntersectionObserver(
      ([{ isIntersecting, boundingClientRect }], observer) => {
        if (isIntersecting) {
          this.fixify();

          // Identify our home positions
          this.setHome(boundingClientRect);
          this.render();

          document.addEventListener("scroll", (event) => {
            this.render();
          });

          window.addEventListener("resize", (event) => {
            this.setHome();
            this.render();
          });

          observer.disconnect();
        }
      },
    ).observe(sketch);
  }

  /*
   * Update the position and cropt of both the rendered and sketched elements
   */
  render() {

    // Find the original sketch position relative to the window
    let sketchRect = this.fixedSketch.getBoundingClientRect();

    // Find the position of the container of the render element, relative to the window
    let containerPos = this.renderEl.parentNode.getBoundingClientRect();

    // Find the target top of the sketch div
    let targetSketchTop = sketchRect.top;

    // Find the target margin of the sketch div
    let containerPosRel = containerPos.top - targetSketchTop;

    // Position the rendered element
    if (containerPosRel < 0) containerPosRel = 0; // Stop if back at original position.
    this.renderEl.style.transform = `translateY(${((containerPosRel) * -1 )}px`;

    if (containerPosRel === 0) {
      this.sketchDiv.style.height = "0px";
    } else {
      this.sketchDiv.style.height = this.sketchMaxHeight + "px";
    }
  }

  /*
   * Make an absolutely positioned clone of the sketch
   * Replace the original with a spacer
   */
  fixify() {

    // Create the sketch element that we will control
    this.fixedSketch = this.sketchHome.cloneNode();
    this.fixedSketch.id = "sketch-fixed";
    this.fixedSketch.classList.remove("sketch");

    // Create a div to contain the sketch and crop it
    this.sketchDiv = document.createElement("div");
    this.sketchDiv.style.position = "fixed";
    this.sketchDiv.style.overflow = "hidden";
    // So that it will appear below the renderContainer.
    this.sketchDiv.style.zIndex = "-1";

    this.containerEl.style.overflow = "hidden";

    this.sketchDiv.appendChild(this.fixedSketch);

    // Replace the original sketch with a spacer
    this.sketchHome.src = "/images/spacer.png";

    // Insert our sketch container into the document
    document.body.appendChild(this.sketchDiv);
  }

  /*
   * Set home positions that can change on resize
   */
  setHome(sketchRect = this.sketchHome.getBoundingClientRect()) {
    this.sketchMaxHeight = sketchRect.height;
    this.sketchDiv.style.left = (sketchRect.x + window.scrollX) + "px";
    this.sketchDiv.style.top = (sketchRect.y + window.scrollY) + "px";
    this.sketchDiv.style.width = sketchRect.width + "px";
  }

};

export default Wiper;
